import React from 'react'
import logo from '../assets/images/viva-logo-white.png'
import '../styles/NavigationBar.scss'

class NavigationBar extends React.Component {
	render() {
		return (
		  <div className='navBar'>
		  	<div className='container'>
	    		<div className='companyName'>
	    			<a href='https://www.vivainterview.com'>
		    			<img className='logo' src={logo} alt='VIVA Interview' />
	    			</a>
		    	</div>
		    	<div className='links'>
		    		<a className='links-item' href='mailto:contact@vivainterview.com'>Contact Us</a>
	    		</div>
    		</div>
  		</div>
    )
	}
}

export default NavigationBar