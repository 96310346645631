import React, { useState, useEffect } from "react";
import dateFormat, { masks } from "dateformat";


import { pdf, Document, Page, View, Text, Image, StyleSheet, Link, Font } from '@react-pdf/renderer';

import img_viva from "../../assets/images/viva-logo-white.png";



import { saveAs } from 'file-saver';
import { useRouteMatch } from "react-router-dom";


Font.register({
    // test
    family: 'Oswald', fonts: [
        { src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'}
    ]
});

const styles = StyleSheet.create({
    bold_text: {
        fontWeight: 700,
        fontStyle: 'bold',
    },
    page_pdf: {
        backgroundColor: "#ffffff"
    },
    container_header_pdf: {
        backgroundColor: "#05214e",
        padding: 20
    },

    image_header_pdf: {
        width: 200
    },

    container_footer_pdf: {
        backgroundColor: "#05214e",
        padding: 20,
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        fontSize: 13,
        color: "#ffffff"
    }

});

var array_radios = [];



const ModalFormPolls = (user) => {

    user = user.user;
    masks.format = 'mmmm dS, yyyy';


    const [useValueInput, setValueInput] = useState({
        name: "",
        institution: "",
        assessment_input1: "",
        assessment_radio1: "",
        assessment_input2: "",
        assessment_radio2: "",
        assessment_input3: "",
        assessment_radio3: "",
        assessment_input4: "",
        assessment_radio4: "",
        assessment_input5: "",
        assessment_radio5: "",
        comments: "",
        student_first_name: user.first_name,
        student_last_name: user.student_last_name,
        uuid: user.uuid,
        conducted_interview_date:user.date,
        reviewed_interview_date: user.date_end,
    });


    // HACER: CREAR STATE Y PASARLE LOS RADIO PARA MANDARLO A IMPRIMIR :D
    const { name, institution, assessment_input1, assessment_radio1, assessment_input2, assessment_radio2,
        assessment_input3, assessment_radio3, assessment_input4, assessment_radio4, assessment_input5, assessment_radio5,
        comments,student_first_name, student_last_name, uuid, conducted_interview_date, reviewed_interview_date } = useValueInput;
    // radios
    const [useRadio1, setRadio1] = useState(0);
    const [useRadio2, setRadio2] = useState(0);
    const [useRadio3, setRadio3] = useState(0);
    const [useRadio4, setRadio4] = useState(0);
    const [useRadio5, setRadio5] = useState(0);

    // sum max by radios 5 5 5 5 5 = 25 total
    const [useRadioMax1, setRadioMax1] = useState(0);
    const [useRadioMax2, setRadioMax2] = useState(0);
    const [useRadioMax3, setRadioMax3] = useState(0);
    const [useRadioMax4, setRadioMax4] = useState(0);
    const [useRadioMax5, setRadioMax5] = useState(0);

    // radio selected
    const [useRadioSelected1, setRadioSelected1] = useState(0);
    const [useRadioSelected2, setRadioSelected2] = useState(0);
    const [useRadioSelected3, setRadioSelected3] = useState(0);
    const [useRadioSelected4, setRadioSelected4] = useState(0);
    const [useRadioSelected5, setRadioSelected5] = useState(0);

    // radio catch last value
    const [useRadioCatchLastValue1, setRadioCatchLastValue1] = useState(0);
    const [useRadioCatchLastValue2, setRadioCatchLastValue2] = useState(0);
    const [useRadioCatchLastValue3, setRadioCatchLastValue3] = useState(0);
    const [useRadioCatchLastValue4, setRadioCatchLastValue4] = useState(0);
    const [useRadioCatchLastValue5, setRadioCatchLastValue5] = useState(0);

    // radio catch max last value
    const [useRadioCatchLastRadioMax1, setRadioCatchLastRadioMax1] = useState(0);
    const [useRadioCatchLastRadioMax2, setRadioCatchLastRadioMax2] = useState(0);
    const [useRadioCatchLastRadioMax3, setRadioCatchLastRadioMax3] = useState(0);
    const [useRadioCatchLastRadioMax4, setRadioCatchLastRadioMax4] = useState(0);
    const [useRadioCatchLastRadioMax5, setRadioCatchLastRadioMax5] = useState(0);

    const [useErrorActive, setErrorActive] = useState(false);
    const [useErrorActiveText, setErrorActiveText] = useState("");


    const [useDownloading, setDownloading] = useState(false);




    useEffect(() => {

        if (assessment_input1 === "" || assessment_radio1 === "") {
            setRadio1(0);
            setRadioMax1(0);
            setRadioSelected1(0);
        } else {
            setRadio1(useRadioCatchLastValue1);
            setRadioMax1(useRadioCatchLastRadioMax1);
            setRadioSelected1(1);
        }

        if (assessment_input2 === "" || assessment_radio2 === "") {
            setRadio2(0);
            setRadioMax2(0);
            setRadioSelected2(0);
        } else {
            setRadio2(useRadioCatchLastValue2);
            setRadioMax2(useRadioCatchLastRadioMax2);
            setRadioSelected2(1);
        }

        if (assessment_input3 === "" || assessment_radio3 === "") {
            setRadio3(0);
            setRadioMax3(0);
            setRadioSelected3(0);
        } else {
            setRadio3(useRadioCatchLastValue3);
            setRadioMax3(useRadioCatchLastRadioMax3);
            setRadioSelected3(1);
        }

        if (assessment_input4 === "" || assessment_radio4 === "") {
            setRadio4(0);
            setRadioMax4(0);
            setRadioSelected4(0);
        } else {
            setRadio4(useRadioCatchLastValue4);
            setRadioMax4(useRadioCatchLastRadioMax4);
            setRadioSelected4(1);
        }

        if (assessment_input5 === "" || assessment_radio5 === "") {
            setRadio5(0);
            setRadioMax5(0);
            setRadioSelected5(0);
        } else {
            setRadio5(useRadioCatchLastValue5);
            setRadioMax5(useRadioCatchLastRadioMax5);
            setRadioSelected5(1);
        }




    }, [
        assessment_input1, useRadioSelected1, assessment_radio1, assessment_input2, useRadioSelected2, assessment_radio2, assessment_input3,
        useRadioSelected3, assessment_radio3, assessment_input4, useRadioSelected4, assessment_radio4,
        assessment_input5, useRadioSelected5, assessment_radio5, useRadioCatchLastValue1, useRadioCatchLastValue2, useRadioCatchLastValue3,
        useRadioCatchLastValue4, useRadioCatchLastValue5, useRadioCatchLastRadioMax1, useRadioCatchLastRadioMax2, useRadioCatchLastRadioMax3,
        useRadioCatchLastRadioMax4, useRadioCatchLastRadioMax5
    ]);





    const onChangeInputValueStudent = (e) => {

        setValueInput({
            ...useValueInput,
            [e.target.name]: e.target.value
        });

    }

    const onClickScoreAssessment = (element) => {

        const { name, value } = element;


        switch (name) {

            case "assessment_radio1":
                setRadio1(parseInt(value || '0', 10));
                setRadioSelected1(1);
                setRadioMax1(5);
                setRadioCatchLastValue1(parseInt(value || '0', 10));
                setRadioCatchLastRadioMax1(5);
                break;

            case "assessment_radio2":
                setRadio2(parseInt(value || '0', 10));
                setRadioSelected2(1);
                setRadioMax2(5);
                setRadioCatchLastValue2(parseInt(value || '0', 10));
                setRadioCatchLastRadioMax2(5);

                break;

            case "assessment_radio3":
                setRadio3(parseInt(value || '0', 10));
                setRadioSelected3(1);
                setRadioMax3(5);
                setRadioCatchLastValue3(parseInt(value || '0', 10));
                setRadioCatchLastRadioMax3(5);

                break;

            case "assessment_radio4":
                setRadio4(parseInt(value || '0', 10));
                setRadioSelected4(1);
                setRadioMax4(5);
                setRadioCatchLastValue4(parseInt(value || '0', 10));
                setRadioCatchLastRadioMax4(5);

                break;

            case "assessment_radio5":
                setRadio5(parseInt(value || '0', 10));
                setRadioSelected5(1);
                setRadioMax5(5);
                setRadioCatchLastValue5(parseInt(value || '0', 10));
                setRadioCatchLastRadioMax5(5);

                break;

            default:
                break;
        }


    }




    const DocumentPdf = () => {

        return (

            // mostrar la información agregada :?
            <Document>
                <Page size="A4" style={styles.page_pdf}>

                    <View style={styles.container_header_pdf}>

                        <Image
                            style={styles.image_header_pdf}
                            source={img_viva}
                        />
                    </View>

                    <View style={{ margin: "2%", fontSize: "10" }}>

                        <Text style={{ fontSize: "23" }}>Portfolio Interview Assessment</Text>

                        <View style={{ marginTop: "3%", marginBottom: "2%", fontSize: "13" }}>
                            <Text style={styles.bold_text}><span style={styles.bold_text}>Student name:</span> {user.last_name + ", " + user.first_name}</Text>
                            <Text><b>Interview link:</b>  <Link src={"https://interviews.vivainterview.com/" + user.uuid}>{"https://interviews.vivainterview.com/" + user.uuid}</Link></Text>
                        </View>

                        <View style={{ marginBottom: "2%", fontSize: "13" }}>
                            <Text><b>Interview conducted by VIVA on:</b> { dateFormat(user.date, "format")}</Text>
                            <Text><b>Interview reviewed by {institution} on:</b> {dateFormat(user.date_end, "format")}</Text>
                            <Text><b>{institution} reviewer:</b> {name} </Text>
                        </View>


                        <View style={{ marginBottom: "2%" }}>

                            {
                                array_radios.map((e, i) => (

                                    <View key={i}>
                                        <Text style={{ fontSize: "13" }}>
                                            Criteria {e.input}:
                                        </Text>
                                        <Text style={{ fontSize: "13" }}>
                                            {e.message}
                                        </Text>

                                        <View style={{ marginTop: "2%" }}>

                                            <View style={{ display: "flex", flexDirection: "row", textAlign: "center" }}>


                                                <View style={{ marginBottom: "2%", width: "25%", fontSize: "11" }}>
                                                    <Text style={{ fontWeight: `${e.radio === "1" ? "900 !important" : ""}`, color: `${e.radio === "1" ? "#ff2554" : ""}` }}>Poor (1)</Text>
                                                </View>



                                                <View style={{ marginBottom: "2%", width: "25%", fontSize: "11" }}>
                                                    <Text style={{ color: `${e.radio === "2" ? "#ff2554" : ""}` }}>Unsatisfactory (2)</Text>
                                                </View>


                                                <View style={{ marginBottom: "2%", width: "25%", fontSize: "11" }}>
                                                    <Text style={{ color: `${e.radio === "3" ? "#ff2554" : ""}` }}>Satisfactory (3)</Text>
                                                </View>

                                                <View style={{ marginBottom: "2%", width: "25%", fontSize: "11" }}>
                                                    <Text style={{ color: `${e.radio === "4" ? "#ff2554" : ""}` }}>Good (4)</Text>
                                                </View>

                                                <View style={{ marginBottom: "2%", width: "25%", fontSize: "11" }}>
                                                    <Text style={{ color: `${e.radio === "5" ? "#ff2554" : ""}` }}>Excellent (5)</Text>
                                                </View>

                                            </View>


                                        </View>

                                    </View>

                                ))
                            }

                        </View>

                        <Text style={{ fontSize: "13" }}>Coments</Text>
                        <Text style={{ marginBottom: "2%", fontSize: "13" }}>{comments}</Text>


                        <Text style={{ marginBottom: "2%", fontSize: "25" }}>
                            Total score: <Text style={{ color: "#ff2554" }}> {total_score}/{max_score} - {isNaN(percentage_score) ? 0 : Math.floor(percentage_score)}%</Text>
                        </Text>


                        <View style={{ fontSize: "13" }}>
                            <Text style={{fontFamily: 'Oswald'}}>
                                For any queries relating to the interview, please email <Link style={{ color: "black", textDecoration: "underline" }} src="mailto:contact@vivainterview.com">contact@vivainterview.com</Link>
                            </Text>
                        </View>



                    </View>


                    <View style={styles.container_footer_pdf}>

                        <Text>
                            © VIVA Video Interviews, all rights reserved
                        </Text>

                        <Link style={{ color: "#ffffff", textDecoration: "none" }} src="www.vivainterview.com">www.vivainterview.com</Link>
                    </View>

                </Page>
            </Document>
        );


    }

    const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));

    async function getProps() {
        await delay(0);

        return ({
            isLoad: true
        });
    }

    const onDownloadPDF = async () => {


        if (name.trim() === "") {
            setErrorActive(true);
            setErrorActiveText("Reviewer name can't be empty");
            return;
        }

        setErrorActive(false);
        setErrorActiveText("");

        if (institution.trim() === "") {
            setErrorActive(true);
            setErrorActiveText("Institution name can't be empty");
            return;
        }

        setErrorActive(false);
        setErrorActiveText("");

        if (assessment_radio1 === "" && assessment_radio2 === "" && assessment_radio3 === "" && assessment_radio4 === "" &&
            assessment_radio5 === "") {
            setErrorActive(true);
            setErrorActiveText("You must submit at least one assessment criteria");
            return;
        }

        setErrorActive(false);
        setErrorActiveText("");



        if (assessment_input1.trim() !== "" && assessment_radio1 !== "") {

            array_radios.push({ input: 1, message: assessment_input1, radio: assessment_radio1 });
        }

        if (assessment_input2.trim() !== "" && assessment_radio2 !== "") {
            array_radios.push({ input: 2, message: assessment_input2, radio: assessment_radio2 });

        }

        if (assessment_input3.trim() !== "" && assessment_radio3 !== "") {
            array_radios.push({ input: 3, message: assessment_input3, radio: assessment_radio3 });

        }

        if (assessment_input4.trim() !== "" && assessment_radio4 !== "") {
            array_radios.push({ input: 4, message: assessment_input4, radio: assessment_radio4 });
        }

        if (assessment_input5.trim() !== "" && assessment_radio5 !== "") {
            array_radios.push({ input: 5, message: assessment_input5, radio: assessment_radio5 });
        }



        const props = await getProps();
        const doc = <DocumentPdf {...props} />;
        const asPdf = pdf([] || null); // {} is important, throws without an argument
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        const nameDocument = user.last_name +"-"+ user.first_name+ "-VIVA-interview-assessment.pdf";
        saveAs(blob, nameDocument);

        setDownloading(true);


        // cleaning array when download is finish
        setTimeout(() => {
            array_radios = [];

            setDownloading(false);

        }, 500);


    }



    const onClearInput = () => {
        setValueInput({
            name: "",
            institution: "",
            assessment_input1: "",
            assessment_radio1: "",
            assessment_input2: "",
            assessment_radio2: "",
            assessment_input3: "",
            assessment_radio3: "",
            assessment_input4: "",
            assessment_radio4: "",
            assessment_input5: "",
            assessment_radio5: "",
            comments: ""
        });
    }



    const total_score = useRadio1 + useRadio2 + useRadio3 + useRadio4 + useRadio5;
    const max_score = useRadioMax1 + useRadioMax2 + useRadioMax3 + useRadioMax4 + useRadioMax5;
    const percentage_score = (total_score / max_score) * 100;



    return (
        <>


            <p id="student-name-load-me" style={{ fontSize: "22px" }}></p>
            <p style={{ fontSize: "22px" }} className="modal-content-p">Reviewer Name: <span className="requiredField">*</span></p>

            <input type="text" id="teacher-name" placeholder="Name of reviewer"
                name="name" value={name} onChange={onChangeInputValueStudent} />

            <p style={{ fontSize: "22px" }}>Institution: <span className="requiredField">*</span></p>
            <input type="text" id="institution-name" placeholder="Name of university/college reviewing interview"
                name="institution" value={institution} onChange={onChangeInputValueStudent} />

            <h2>Results:</h2>

            <p className="requiredFieldExplanation">(at least one assessment criteria must be filled)</p>


            <table>
                <tbody>
                    <tr>
                        <th className="myQuestion">Assessment Criteria</th>
                        <th className="table-score">Weak</th>
                        <th className="table-score">Satisfactory</th>
                        <th className="table-score">Good</th>
                        <th className="table-score">Very Good</th>
                        <th className="table-score">Excellent</th>
                    </tr>


                    <tr className="text-score-style text-score-desktop">
                        <td></td>
                        <td>(1)</td>
                        <td>(2)</td>
                        <td>(3)</td>
                        <td>(4)</td>
                        <td>(5)</td>
                    </tr>

                    {/* 1 */}
                    <tr className="text-score-style mobile-question-title">
                        <td>(1)</td>
                        <td>(2)</td>
                        <td>(3)</td>
                        <td>(4)</td>
                        <td>(5)</td>
                    </tr>

                    <tr style={{ marginTop: "10px" }}>

                        <td><input className="questionText" type="text" placeholder="Assessment criteria 1"
                            name="assessment_input1" value={assessment_input1} onChange={onChangeInputValueStudent} /></td>

                        <td><input onClick={(e) => onClickScoreAssessment(e.target)} type="radio" name="assessment_radio1" disabled={assessment_input1 === "" ? true : false} checked={assessment_input1 === "" ? "" : assessment_radio1 === "1"} value="1" onChange={onChangeInputValueStudent} /></td>
                        <td><input onClick={(e) => onClickScoreAssessment(e.target)} type="radio" name="assessment_radio1" disabled={assessment_input1 === "" ? true : false} checked={assessment_input1 === "" ? "" : assessment_radio1 === "2"} value="2" onChange={onChangeInputValueStudent} /></td>
                        <td><input onClick={(e) => onClickScoreAssessment(e.target)} type="radio" name="assessment_radio1" disabled={assessment_input1 === "" ? true : false} checked={assessment_input1 === "" ? "" : assessment_radio1 === "3"} value="3" onChange={onChangeInputValueStudent} /></td>
                        <td><input onClick={(e) => onClickScoreAssessment(e.target)} type="radio" name="assessment_radio1" disabled={assessment_input1 === "" ? true : false} checked={assessment_input1 === "" ? "" : assessment_radio1 === "4"} value="4" onChange={onChangeInputValueStudent} /></td>
                        <td><input onClick={(e) => onClickScoreAssessment(e.target)} type="radio" name="assessment_radio1" disabled={assessment_input1 === "" ? true : false} checked={assessment_input1 === "" ? "" : assessment_radio1 === "5"} value="5" onChange={onChangeInputValueStudent} /></td>
                    </tr>

                    {/* 2 */}
                    <tr className="text-score-style mobile-question-title">
                        <td>(1)</td>
                        <td>(2)</td>
                        <td>(3)</td>
                        <td>(4)</td>
                        <td>(5)</td>
                    </tr>
                    <tr style={{ marginTop: "10px" }}>
                        <td><input className="questionText" type="text" placeholder="Assessment criteria 2"
                            name="assessment_input2" value={assessment_input2} onChange={onChangeInputValueStudent} /></td>
                        <td><input onClick={(e) => onClickScoreAssessment(e.target)} type="radio" name="assessment_radio2" disabled={assessment_input2 === "" ? true : false} checked={assessment_input2 === "" ? "" : assessment_radio2 === "1"} value="1" onChange={onChangeInputValueStudent} /></td>
                        <td><input onClick={(e) => onClickScoreAssessment(e.target)} type="radio" name="assessment_radio2" disabled={assessment_input2 === "" ? true : false} checked={assessment_input2 === "" ? "" : assessment_radio2 === "2"} value="2" onChange={onChangeInputValueStudent} /></td>
                        <td><input onClick={(e) => onClickScoreAssessment(e.target)} type="radio" name="assessment_radio2" disabled={assessment_input2 === "" ? true : false} checked={assessment_input2 === "" ? "" : assessment_radio2 === "3"} value="3" onChange={onChangeInputValueStudent} /></td>
                        <td><input onClick={(e) => onClickScoreAssessment(e.target)} type="radio" name="assessment_radio2" disabled={assessment_input2 === "" ? true : false} checked={assessment_input2 === "" ? "" : assessment_radio2 === "4"} value="4" onChange={onChangeInputValueStudent} /></td>
                        <td><input onClick={(e) => onClickScoreAssessment(e.target)} type="radio" name="assessment_radio2" disabled={assessment_input2 === "" ? true : false} checked={assessment_input2 === "" ? "" : assessment_radio2 === "5"} value="5" onChange={onChangeInputValueStudent} /></td>
                    </tr>

                    {/* 3 */}
                    <tr className="text-score-style mobile-question-title">
                        <td>(1)</td>
                        <td>(2)</td>
                        <td>(3)</td>
                        <td>(4)</td>
                        <td>(5)</td>
                    </tr>
                    <tr style={{ marginTop: "10px" }}>
                        <td><input className="questionText" type="text" placeholder="Assessment criteria 3"
                            name="assessment_input3" value={assessment_input3} onChange={onChangeInputValueStudent} /></td>
                        <td><input onClick={(e) => onClickScoreAssessment(e.target)} type="radio" name="assessment_radio3" disabled={assessment_input3 === "" ? true : false} checked={assessment_input3 === "" ? "" : assessment_radio3 === "1"} value="1" onChange={onChangeInputValueStudent} /></td>
                        <td><input onClick={(e) => onClickScoreAssessment(e.target)} type="radio" name="assessment_radio3" disabled={assessment_input3 === "" ? true : false} checked={assessment_input3 === "" ? "" : assessment_radio3 === "2"} value="2" onChange={onChangeInputValueStudent} /></td>
                        <td><input onClick={(e) => onClickScoreAssessment(e.target)} type="radio" name="assessment_radio3" disabled={assessment_input3 === "" ? true : false} checked={assessment_input3 === "" ? "" : assessment_radio3 === "3"} value="3" onChange={onChangeInputValueStudent} /></td>
                        <td><input onClick={(e) => onClickScoreAssessment(e.target)} type="radio" name="assessment_radio3" disabled={assessment_input3 === "" ? true : false} checked={assessment_input3 === "" ? "" : assessment_radio3 === "4"} value="4" onChange={onChangeInputValueStudent} /></td>
                        <td><input onClick={(e) => onClickScoreAssessment(e.target)} type="radio" name="assessment_radio3" disabled={assessment_input3 === "" ? true : false} checked={assessment_input3 === "" ? "" : assessment_radio3 === "5"} value="5" onChange={onChangeInputValueStudent} /></td>
                    </tr>

                    {/* 4 */}
                    <tr className="text-score-style mobile-question-title">
                        <td>(1)</td>
                        <td>(2)</td>
                        <td>(3)</td>
                        <td>(4)</td>
                        <td>(5)</td>
                    </tr>
                    <tr style={{ marginTop: "10px" }}>
                        <td><input className="questionText" type="text" placeholder="Assessment criteria 4"
                            name="assessment_input4" value={assessment_input4} onChange={onChangeInputValueStudent} /></td>
                        <td><input onClick={(e) => onClickScoreAssessment(e.target)} type="radio" name="assessment_radio4" disabled={assessment_input4 === "" ? true : false} checked={assessment_input4 === "" ? "" : assessment_radio4 === "1"} value="1" onChange={onChangeInputValueStudent} /></td>
                        <td><input onClick={(e) => onClickScoreAssessment(e.target)} type="radio" name="assessment_radio4" disabled={assessment_input4 === "" ? true : false} checked={assessment_input4 === "" ? "" : assessment_radio4 === "2"} value="2" onChange={onChangeInputValueStudent} /></td>
                        <td><input onClick={(e) => onClickScoreAssessment(e.target)} type="radio" name="assessment_radio4" disabled={assessment_input4 === "" ? true : false} checked={assessment_input4 === "" ? "" : assessment_radio4 === "3"} value="3" onChange={onChangeInputValueStudent} /></td>
                        <td><input onClick={(e) => onClickScoreAssessment(e.target)} type="radio" name="assessment_radio4" disabled={assessment_input4 === "" ? true : false} checked={assessment_input4 === "" ? "" : assessment_radio4 === "4"} value="4" onChange={onChangeInputValueStudent} /></td>
                        <td><input onClick={(e) => onClickScoreAssessment(e.target)} type="radio" name="assessment_radio4" disabled={assessment_input4 === "" ? true : false} checked={assessment_input4 === "" ? "" : assessment_radio4 === "5"} value="5" onChange={onChangeInputValueStudent} /></td>
                    </tr>

                    {/* 5 */}
                    <tr className="text-score-style mobile-question-title">
                        <td>(1)</td>
                        <td>(2)</td>
                        <td>(3)</td>
                        <td>(4)</td>
                        <td>(5)</td>
                    </tr>
                    <tr style={{ marginTop: "10px" }}>
                        <td><input className="questionText" type="text" placeholder="Assessment criteria 5"
                            name="assessment_input5" value={assessment_input5} onChange={onChangeInputValueStudent} /></td>
                        <td><input onClick={(e) => onClickScoreAssessment(e.target)} type="radio" name="assessment_radio5" disabled={assessment_input5 === "" ? true : false} checked={assessment_input5 === "" ? "" : assessment_radio5 === "1"} value="1" onChange={onChangeInputValueStudent} /></td>
                        <td><input onClick={(e) => onClickScoreAssessment(e.target)} type="radio" name="assessment_radio5" disabled={assessment_input5 === "" ? true : false} checked={assessment_input5 === "" ? "" : assessment_radio5 === "2"} value="2" onChange={onChangeInputValueStudent} /></td>
                        <td><input onClick={(e) => onClickScoreAssessment(e.target)} type="radio" name="assessment_radio5" disabled={assessment_input5 === "" ? true : false} checked={assessment_input5 === "" ? "" : assessment_radio5 === "3"} value="3" onChange={onChangeInputValueStudent} /></td>
                        <td><input onClick={(e) => onClickScoreAssessment(e.target)} type="radio" name="assessment_radio5" disabled={assessment_input5 === "" ? true : false} checked={assessment_input5 === "" ? "" : assessment_radio5 === "4"} value="4" onChange={onChangeInputValueStudent} /></td>
                        <td><input onClick={(e) => onClickScoreAssessment(e.target)} type="radio" name="assessment_radio5" disabled={assessment_input5 === "" ? true : false} checked={assessment_input5 === "" ? "" : assessment_radio5 === "5"} value="5" onChange={onChangeInputValueStudent} /></td>
                    </tr>
                </tbody>

            </table>

            <p style={{ fontSize: "22px" }}>Comments:
                <span id="comments-progress" style={{ color: "gray" }}>({comments.length}/250)</span>
            </p>

            <textarea maxLength="250" rows="3" id="comments-score" className="text-area-modal-style"
                name="comments" value={comments} onChange={onChangeInputValueStudent}>
            </textarea>

            <p style={{ fontSize: "22px" }}>Total score:
                <span id="score-n" style={{ fontWeight: "bold" }}>


                    {total_score}/{max_score}

                </span> - <span id="score-p" style={{ fontWeight: "bold" }}> {isNaN(percentage_score) ? 0 : Math.floor(percentage_score)}%</span>
            </p>

            <div style={{ textAlign: "left" }}>

                <span onClick={onDownloadPDF} className="btn-interview-assessment-custom " id="download-pdf" style={{ marginRight: "8px", pointerEvents: `${useDownloading ? "none" : ""}` }}>Download PDF</span>

                <span onClick={onClearInput} className="btn-interview-assessment-custom " id="clear-score">Clear</span>

                {
                    useErrorActive ? (
                        <span id="form-submit-error" style={{ paddingLeft: "25px", color: "red" }}>
                            {useErrorActiveText}
                        </span>
                    ) : null
                }


            </div>


        </>
    );

}









export default ModalFormPolls;