
import React from 'react';

import videojs from 'video.js';

import colors from '../../styles/colors.scss';

// import userData from '../../assets/data/testData';

// import linkedinIcon from '../../assets/images/linkedinIcon.png';

import axios from 'axios';


import pdfTest from "../../assets/data/pdf_test.pdf";



class ConfigPDFPreviewer extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoaded: false,
        }
    }

    popoutPortfolio = link => {
        window.open(
            link,
            'open_window',
            'menubar=no, toolbar=no, location=no, directories=no, status=no, scrollbars=no, resizable=no, dependent, width=800, height=620, left=0, top=0'
        )
    }

    async componentDidMount() {
        const uuid = this.props.match.params.uuid
        const proxyurl = "https://cors-anywhere.herokuapp.com/"
        const interviewurl = "http://test.vivainterview.com/wp-json/api/interview/" + uuid;
        await axios.get(proxyurl + interviewurl, {
            // Axios looks for the `auth` option, and, if it is set, formats a
            // basic auth header for you automatically.
            auth: {
                username: 'user',
                password: 'password!'
            }
        }).then(res => {
            this.setState({
                users: res.data,
                isLoaded: true,
                videoSource: process.env.REACT_APP_VIDEO_DOMAIN + "/" + res.data.videoSource,
                portfolioSource: process.env.REACT_APP_VIDEO_DOMAIN + "/" + res.data.portfolioSource,
                isVideo: true
            })
        })

        const markersList = this.state.users.markers.map((secondsList) => {
            return {
                time: secondsList.seconds
            }
        })

        this.myPlayer = videojs('my-player',
            {
                controls: true,
                autoplay: false,
            }
        )

        this.myPlayer.markers({
            markerStyle: {
                'width': '8px',
                'height': '30px',
                'borderRadius': '0',
                'top': '-13px',
                'backgroundColor': colors.primary,
            },

            markerTip: {
                display: false,
            },

            markers: markersList
        });
    }


    render() {
        const { isLoaded, users, portfolioSource } = this.state;

        // console.log(portfolioSource)

        return (
            <>

                <h3>Portfolio</h3>

                {!isLoaded ?
                    <div style={{ height: '100%' }}>
                        <div className="statbar">
                            <a onClick={() => { this.popoutPortfolio(portfolioSource) }} target="_blank"
                                className="linkToOpenPortfolio">Open In New Window</a>
                        </div>
                        {/* <embed type="application/pdf" src={portfolioSource + '#view=fit'} title="Portfolio PDF" width="100%" height="100%" /> */}
                    
                    
                        <embed type="application/pdf" src={pdfTest} title="Portfolio PDF" width="100%" height="100%" />

                    </div> : <b>Loading..</b>
                }
            </>
        )
    }
}

export default ConfigPDFPreviewer;
