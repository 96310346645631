import React from 'react'


import NavigationBar from './NavigationBar'

import { BrowserRouter as Router, Route, Link } from "react-router-dom";


import MainVideoDisplay from './interviewer/MainVideoDisplay';
import VideoDisplay from '../containers/VideoDisplay';




const InterviewDisplay = props => {

  const { t } = props

  return (

    <div className="App">
      
      <NavigationBar />
      <VideoDisplay {...props} />
     {/* <MainVideoDisplay {...props} /> */}


    </div>
  )
}

export default InterviewDisplay