
import React from "react";



import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';


const ModalAboutViva = ({ showModalAbout, onCloseModalAbout }) => {

    function greetUser() {
        console.log("HI! How are you?");
    }
    
    console.log("showModalAbout", showModalAbout);

    return (
        < >
            <Modal onClick={greetUser} backdrop="static"
                keyboard={false} className="modal  modal-custom" show={showModalAbout} onHide={onCloseModalAbout} animation={false} size="lg">
                <div>
                    <Modal.Header closeButton onMouseDown={(e) => e.preventDefault()}>
                    </Modal.Header>
                </div>
                <Modal.Body >



                    <h3>Why am I receiving this?</h3>


                    <p className="text-about-viva-custom" style={{ fontSize: "1.2em", color: "#444444", marginBottom: "2%", }}>
                        You have received <span style={{ textDecoration: "underline" }}>this independently-conducted interview recording</span> as the applicant has decided to provide this as a supplementary material to support their application to your institution.
                    </p>


                    <p className="text-about-viva-custom" style={{ fontSize: "1.2em", color: "#444444", marginBottom: "5%" }}>
                        VIVA interviews, which are conducted by experienced former admissions tutors working in an independent capacity, are intended to support and enhance applications by giving students an additional opportunity to
                        discuss their work in an authentic and accessible way.
                    </p>


                    <h3>What is VIVA?</h3>

                    <p className="text-about-viva-custom" style={{ color: "#444444", marginBottom: "2%" }}>
                        <em>VIVA Video Interviews</em> is an innovative third-party ‘EdTech’ platform for international applicants to higher art
                        and design education.

                    </p>

                    <p className="text-about-viva-custom" style={{ color: "#444444", marginBottom: "2%" }}>
                        Students voluntarily register to take part in a VIVA interview, wherever they are in the world and to whichever institution they are
                        applying, to further demonstrate their engagement with their practice, key academic competencies, suitability for higher education,
                        communication skills and to assure institutions of the authenticity of the application portfolio.
                    </p>

                    <p className="text-about-viva-custom" style={{ color: "#444444", marginBottom: "2%" }}>
                        The full, unedited and ID-verified video interview has been uploaded to VIVA’s platform and made available to the admissions offices
                        that the student has selected to share it with via embedded link within their portfolio or written statements. The enclosed video
                        and transcribed/timestamped interview questions provide universities with a valuable supplementary material to make a student’s
                        application more transparent and engaging. 
                    </p>

                    <p className="text-about-viva-custom" style={{ color: "#444444", marginBottom: "5%" }}>
                        <b>Please note:</b> VIVA interviews are intended to provide individual students with an OPTIONAL further opportunity to present their creative
                        practice within the university application process, and are in no way intended to replace universities’ own admissions interviews or
                        processes.

                    </p>



                    <h3>ABOUT US</h3>


                    <p className="text-about-viva-custom" style={{ color: "#444444" }}>
                        For more information about VIVA, please visit the <a href="https://vivainterview.com/about/">About Us</a> and <a href="https://vivainterview.com/partners/">Information for Admissions Stakeholders</a> pages
                        of the VIVA website, or email us at <a href="mailto:contact@vivainterview.com">contact@vivainterview.com</a>
                    </p>











                </Modal.Body>
            </Modal>

        </>
    );

}

export default ModalAboutViva;