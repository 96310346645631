
import React, { useState } from "react";



import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ModalFormPolls from "./ModalFormPolls";


const ModalInterviewAssessment = ({useShowModal, onCloseModal, users }) => {

    console.log("users var", users);

    return (
        < >

            <Modal backdrop="static"
                keyboard={false} className="modal  modal-custom" show={useShowModal} onHide={onCloseModal} animation={false} size="lg">
                <div style={{ marginBottom: "-20px" }}>
                    <Modal.Header closeButton onMouseDown={(e) => e.preventDefault()}>
                        <h1>Interview Assessment</h1>
                    </Modal.Header>
                    <p className="disclaimer">Disclaimer: The following optional interview assessment form is for use by institutions’ own reviewers. Data inputted into the form below is available only as a local download and is neither stored on VIVA servers nor shared with the interviewee or any other party.</p>
                    <p className="requiredFieldExplanation">(components with an asterisk are required)</p>
                </div>

                <Modal.Body >
                    <ModalFormPolls user={users}/>
                </Modal.Body>
            </Modal>

        </>
    );

}

export default ModalInterviewAssessment;