import React from 'react'
import videojs from 'video.js'
import colors from '../styles/colors.scss'
import 'videojs-markers'
import 'video.js/dist/video-js.css'
import 'videojs-markers/dist/videojs.markers.css'
import '../styles/videoDisplay.scss'
// import userData from '../assets/data/testData'
import linkedinIcon from '../assets/images/linkedinIcon.png'
import axios from 'axios'

import ModalAboutViva from "./interviewer/ModalAboutViva";
import ConfigPDFPreviewer from "./interviewer/ConfigPDFPreviewer";
import ModalInterviewAssessment from "./interviewer/ModalInterviewAssessment";
class VideoPlayer extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			users: {},
			isLoaded: false,
			videoSource: null,
			isVideo: false,
			showModal: false,
			showModalAbout: false,
			useShowModalAbout: false,
			useShowModal: false,

		}
	}

    onCloseModal = () => {
        this.setState({
			...this.props,
			useShowModal: false
		})
    }

    onOpenModal = () => {
        this.setState({
			...this.props,
			useShowModal: true
		})
    }

    onCloseModalAbout = () => {
        this.setState({
			...this.props,
			showModalAbout: false
		})
    }

    onOpenModalAbout = () => {
        this.setState({
			...this.props,
			showModalAbout: true
		})
		console.log(this.state);
    }
	setTime = time => {
		this.myPlayer.currentTime(time)
		this.myPlayer.play()
	}

	convertToHms = seconds => {
		return (
			new Date(seconds * 1000).toISOString().substr(11, 8)
		)
	}

  popoutPortfolio = link => {
		window.open(
			link,
      'open_window',
      'menubar=no, toolbar=no, location=no, directories=no, status=no, scrollbars=no, resizable=no, dependent, width=800, height=620, left=0, top=0'
    )
	}

  async componentWillMount() {
		const uuid = this.props.match.params.uuid
	  const interviewurl = "https://vivainterview.com/wp-json/api/interview/"+uuid;
	  console.log("INTERVIEW URL: ", interviewurl);
		await axios.get(interviewurl).then(res => {
			this.setState({
				users:res.data,
				isLoaded: true,
				videoSource: process.env.REACT_APP_VIDEO_DOMAIN+res.data.videoSource,
				portfolioSource: process.env.REACT_APP_PORTFOLIO_DOMAIN+res.data.portfolioSource,
				isVideo: true
			})
		})
		var markersList = [];
		if(this.state.users.markers != null) {
			markersList = this.state.users.markers.map((secondsList) => {
				return {
					time: secondsList.seconds
				}
			})
		}

    this.myPlayer = videojs('my-player',
	    {
				controls: true,
				autoplay: false,
	    }
    )

    this.myPlayer.markers({
    	markerStyle: {
    		'width': '8px',
    		'height': '30px',
    		'borderRadius': '0',
    		'top': '-13px',
    		'backgroundColor': colors.primary,
		  },

		  markerTip:{
	      display: false,
	   	},

		  markers: markersList
		});
  }

  componentWillUnmount() {
    if (this.myPlayer) {
      this.myPlayer.dispose()
    }
  }
  render() {
	const { isLoaded, videoSource, isVideo, portfolioSource, users} = this.state;
	var last_name_cap = users.last_name;
	if(users.last_name != null) {
		last_name_cap = users.last_name.toUpperCase();
	}
    return (
    	<div className="container" style={{textAlign: 'left', height: '100%'}}>
    		<div className="statbar">
    			<div className="statbar-name">
		    		<h1 className="header-name">{last_name_cap}, {this.state.users.first_name}</h1>
		    		<div style={{ display: 'flex' }}>
			    		<p className="header-info"><b>Date:<br /></b>{isLoaded ? this.state.users.date : <b></b>}</p>
			    		<p className="header-info">
			    			<b>Interviewer:
			    			<br />
							</b>{isLoaded ? this.state.users.interviewer : <b></b>}
							{isLoaded && this.state.users.interviewerUrl != null ?
			    			<div style={{float: 'right'}}>
				    			<a href={this.state.users.interviewerUrl} target="_blank" rel="noopener noreferrer">
				    				<img className="interviewer-social-icon" src={linkedinIcon} alt='LinkedIn' />
			    				</a>
		    				</div>
							: <div></div>}
	    				</p>
		    		</div>
	    		</div>
				
				<div className="statbar-actions">
                        <button onClick={this.onOpenModalAbout} onMouseDown={(e) => e.preventDefault()} className="action-item  btn-interview-assessment-custom-about ">About VIVA</button>
                        <button onClick={this.onOpenModal} onMouseDown={(e) => e.preventDefault()} className="action-item  btn-interview-assessment-custom ">Interview Assessment</button>
				</div>
{/*	    		<div className="statbar-actions">
	    			<a className='action-item' href='/'>Back to Home</a> 
	    			<button className="action-item btn">Redo Interview</button>
	    			<button className="action-item btn btn-primary">Approve</button>
	    		</div>*/}
    		</div>
    		<div className="video-section">
		      <div data-vjs-player className="vjs-container" >	
			  {isVideo ? 
			  
		        <video id="my-player" controls preload="auto" className="video-js" crossOrigin="anonymous">
		        	<source 
		        		src={videoSource}
		        		type="video/mp4">
		      		</source>
		        </video> : <b>Loading...</b>
				}
		      </div>
		      <div className="playlist-container">
				  {isLoaded && this.state.users.markers != null ? 
		      	<h3>{this.state.users.markers.length} Questions</h3> : <b></b>
				}
				  {isLoaded && this.state.users.markers != null ?
		      	this.state.users.markers.map((questionList, index) => {	
		      		return (
		      			<div key={index} className="playlist-item" onClick={() => this.setTime(questionList.seconds)}>
					      	<div className="playlist-item-number">
						      	{index + 1}
					      	</div>
					      	<div className="playlist-item-question">
					      		{questionList.question} <span className="playlist-item-time">({questionList.time})</span>
				      		</div>
			      		</div>
	      			)
		      	}): <b></b>}
		      </div>
	      </div>
	      <div className="portfolio-container"> 
	      	<h3>Portfolio</h3>
			  { isLoaded ?
			  	<div style={{height: '100%'}}>
		      	<div className="statbar">
			      	<a onClick={() => {this.popoutPortfolio(portfolioSource)}} target="_blank">Open In New Window</a>
		      	</div>
			      <embed type="application/pdf" src={portfolioSource + '#view=fit'} title="Portfolio PDF" width="100%" height="100%" />
		      </div> : <b>Loading..</b>
				}
		   </div>
		
	<ModalAboutViva showModalAbout={this.state.showModalAbout} onCloseModalAbout={this.onCloseModalAbout}/>
	<ModalInterviewAssessment users={this.state.users} useShowModal={this.state.useShowModal} onCloseModal={this.onCloseModal} />	
      </div>
    )
  }
}

export default VideoPlayer