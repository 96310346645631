import React from 'react'
import './App.css'
import './Interviewer.css';
import './styles/fonts.scss'
import VideoDisplay from './containers/VideoDisplay'
import NavigationBar from './containers/NavigationBar'
import InterviewDisplay from './containers/interviewDisplay'
import { BrowserRouter as Router, Route } from "react-router-dom";


const App = props => {
  return (
    <Router basename="/interviews">
      <Route path="/:uuid?" render={props => <InterviewDisplay {...props} />}
      />
    </Router>
  )
}

export default App
